import React from 'react'
import "../style/myStyle.scss"

import Layout from '../components/layout'
import InfoCard from '../components/infoCard'
import SEO from '../components/seo'

import ShopBanner from '../components/shopBanner'
import Footer from '../components/footer'

const IndexPage = ({location}) => {
 return (
   <Layout location={location}>
     <SEO title="St-Martin - Le Tshirt" keywords={[`tshirt`, `home`, `bio`, `engagé`]} />
     <ShopBanner />
     <section className="section">
       <div className="columns">
         <div className="column">
           <InfoCard title="Engagé ?" logo="../FCurie.png">
             La moitié des bénéfices va à l’<a href="https://curie.fr/" target="_blank" rel="noreferrer">Institut Curie</a>. Par ce que le cancer, notamment celui du sein, est une saleté dont il faut se débarrasser !
           </InfoCard>
         </div>
         <div className="column">
           <InfoCard title="Bio ?" logo="../Coton.png">
             Un coton tout doux tout bio ! Certifié <a href="https://www.fairwear.org/" target="_blank" rel="noreferrer">Fair Wear</a> pour un respect des conditions de travail et de l’environnement.
           </InfoCard>
         </div>
         <div className="column">
           <InfoCard title="Fun ?" logo="../Coquillage.png">
             Décomplexé, féministe, coloré, audacieux, vitaminé ! St-Martin est une marque bonne humeur, et Jacques en est l’étendard. Boobs out!
           </InfoCard>
         </div>
       </div>
     </section>
     <Footer/>
   </Layout>
 )
}

export default IndexPage
